#toolbar {
  position: fixed;
  right: 020px;
  top: 350px;
  display: flex;
  gap: 20px; 
  flex-direction: column;
  align-items: flex-end;
  z-index: 99;
  .toolbar-row {
    display: flex;
    align-items: center;
    .blue-point-hover {
      padding: 0px 14px 0px 14px;
      line-height: normal;
      background-color: #3550a0;
      // height: 22px;
      border-radius: 3px;
      color: #ddd;
      font-size: 12px;  
      margin-right: 8px;
      display: none;
      align-items: center;  
      justify-content: center;
    }
    .blue-point {
      background-color: #3550a0;
      width:16px;
      height:16px;
      border-radius: 3px;
      cursor:pointer;
      margin:0px;
      transition: all 0.3s;
      padding: 0px;
      &:hover, &.active{ 
        .blue-point{transform: scale(1.375);}
      }
    }
    .blue-point.hover {
      transform: scale(1.375);
    }
  }
}