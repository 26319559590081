.logo {
  padding-top:58px;
}
.header-wrapper-home, .home_banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/home/section-1-bg.jpg);
  // min-height: 945px;

  background-attachment: fixed;
  background-repeat: repeat;
  position: relative;
  background-position: 
  0 20%,
  0 100%,
  0 50%,
  0 100%,  
  0 0;
background-size: 
  2500px,
  800px,
  500px 200px,
  1000px,
  400px 260px;
animation: 150s para infinite linear;  
 
}




@keyframes para {
	100% {
		background-position: 
			-5000px 20%,
			-800px 95%,
			500px 50%,
			1000px 100%,
			400px 0;
		}
	}
 

.header {
  position: relative;
}
#navigation {
  margin-top: 47px;
}
.blue-breaker {
  display: block;
  width:185px;
  height:3px;
  background-color: #3550a0;
  margin-bottom:50px;
}
.section-1 {
  // position: absolute; 
  // top:300px;
  // left:0;

  .blue-box {
    position: absolute;
    background-color: #3550a0;
    padding-top:50px;
    padding-left:35px;
    width:700px;
    height:408px;
    border-radius: 10px;

    z-index:5;
    h1 {
      color:#fff;
      font-size:60px;
      font-weight: 600;
      margin:0;
    }
    h2 {
      color:#fff;
      font-size:40px;
      font-weight: 600;
      margin:0;
    }
    p {
      color:#fff;
      font-size:22px;
      margin:0;
    }
    .blue-box-buttons-flex{
      display: flex;
      position: absolute;
      gap:20px;
      bottom: -40px;
      .white-contact {
        border-radius: 4px;
        background-color: #fff;
        color:#3550a0;
        font-size: 22px;
        font-weight: 700;
        text-decoration: none;
        background-repeat: no-repeat;
        background-image: url(../images/home/post.png);
        background-position: 34px;
        display: block;
        padding-top:20px;
        padding-bottom:20px;
        padding-left:77px;
        width:238px;
      }
      .black-contact {
        border-radius: 4px;
        background-color: #000;
        color:#fff;
        font-size: 22px;
        font-weight: 700;
        text-decoration: none;
        background-repeat: no-repeat;
        background-image: url(../images/home/phone.png);
        background-position: 34px;
        display: block;
        padding-top:20px;
        padding-bottom:20px;
        width:180px;
        padding-left:75px;
      }
    }
  }
  .blue-box-outerline {
    background: unset;
    z-index:4;
    width:700px;
    height:408px;
    border-radius: 10px;
    padding-top:50px;
    padding-left:35px;
    border:1px solid #fff;
    position: absolute;
    top:15px;
    left:17px;
  }
  .blue-box-shadow {
    background: unset;
    z-index:3;
    width:700px;
    height:408px;
    border-radius: 10px;
    padding-top:50px;
    padding-left:35px;
    position: absolute;
    box-shadow: 40px 40px 50px 8px rgba(0,0,0,0.5);
  }
}

.section-2 {
  background-repeat: no-repeat;
  background-image: url(../images/home/dots-right.png);
  background-position: top right;
  background-color: #FFF;
  padding-top:150px; 
  min-height: auto;
  float: left;
  width: 100%;  
  padding-bottom: 140px; 
  .section-2-inner {
    display: flex;
    align-items: flex-start;
    width:100%;
    justify-content: space-between;
    .left-side {
      width:50%;
      max-width: 522px;
      h2 {
        font-size: 33px;
        font-weight: bold;
        text-transform: uppercase;
        color:#3550a0;
        padding-bottom:15px;
        margin-bottom: 35px;
      }
      p {
        font-size: 16px;
        line-height: 28px;
        color:#595d60;
      }
    }
    .right-side {
      width:50%;
      max-width: 522px;
      position: relative;
      .bigger-img {
        width: 469px;
        height: 471px;
        overflow: hidden;
        border-radius: 7px;
        float: right; 
      }
      .smaller-img{
        position: absolute;
        left:0;
        top:240px;
        z-index: 5;
        width:313px;
        height:315px;
        box-shadow: 0 0 50px rgba(45,50,53,0.6);
        border-radius: 7px;
        overflow: hidden;
      }
    }
  }
}
.section-3 {
  background-repeat: no-repeat;
  background-image: url(../images/home/dots-left.png);
  background-position: bottom left;
  background-color: #FFF;  
  padding-top: 20px;
  padding-bottom: 280px;  
  .section-3-inner {
    display: flex;
    width:100%;
    justify-content: space-between;
    gap:100px;
    .left-side {
      width: 50%;
      max-width: 522px;
      min-height: 464px;
      background-color: #f8f9fa;
      padding:40px;
      border-radius: 4px;
      p {
        color:#000;
        font-size: 16px;
        line-height: 28px;
      }
      p:first-child {
        margin-top:0;
      }
    }
    .right-side {
      width: 50%;
      max-width: 522px;
      min-height: 464px;
      padding:40px;
      border: 3px solid #3550a0;
      border-radius: 7px;
      p {
        color:#595d60;
        font-size: 16px;
        line-height: 28px;
        strong {
          color:#3550a0;
          font-size: 18px;
        }
      }
      p:first-child {
        margin-top:0;
      }
    }
  }
}

.section-4 {
  .section-4-inner {  
    margin-top: -123px; 
    padding: 50px;
    background-color: #3550a0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin-bottom: -123px;
    z-index: 9;
    position: relative;
    .left-side {
      color:#fff;
      font-size:32px;
      font-weight:600;
      width:65%;
    }
    .right-side {
      width:35%;
      display: flex;
      justify-content: end;
      a {
        background-color: #fff;
        color:#3550a0;
        font-size:28px;
        font-weight:600;
        border-radius: 7px;
        text-decoration: none;
        padding:25px 48px;
      }
    }
  }
}

.section-5 {
  background: #fff;
  background: linear-gradient(to bottom,  #d3d3d3 0%,#ffffff 15%);
  padding-top:260px;
  .section-5-inner {
    h2 {
      color:#3550a0;
      font-size:33px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 45px;
    }
    .three-boxes {
      display: flex;
      gap:30px;
      justify-content: space-between;
      margin-top:115px;
      .box-content {
        width:33%;
        padding: 30px 40px;
        max-width: 363px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 0 160px rgba(0,0,0,0.06);
        display: flex;
        flex-direction: column;
        cursor: pointer;
        align-items: center; 
        transition: all 0.4s;
        .img-height {
          height: 135px;
        }
        img {
          // height:75px;
          width:auto;
          padding-top:40px;
        }
        .mini-headline {
          color:#000;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 12px;  
        }
        p {
          color:#6d7276;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
        }
        &:hover{
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);
          background-color: #3550a0;
          .img-height{filter: brightness(5);}
          .mini-headline{color: #FFF;}
          p{color: #FFF;}
        } 
      }
      .box-content:hover {
        transform: scale(1.1);
      }
    }
  }
}


.section-6 {
  margin-bottom:163px;
  .section-6-inner {
    .two-boxes {
      display: flex;
      gap:30px;
      justify-content: space-between;
      margin-top:30px;
      .box-content {
        width:50%;
        cursor: pointer;
        padding-left:50px;
        padding-right: 50px;
        max-width: 560px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 0 160px rgba(0,0,0,0.06);
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 0.4s;
        padding-bottom: 25px; 
        .img-height {
          height: 128px; 
        }
        img {
          // height:75px;
          width:auto;
          padding-top:40px;
        }
        .mini-headline {
          color:#000;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 12px;  
        }
        p {
          color:#6d7276;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
        }
        &:hover{
          background-color: #3550a0;
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5); 
          .img-height{filter: brightness(5);}
          .mini-headline{color: #FFF;}
          p{color: #FFF;}
        }
      }
      .box-content:hover {
        transform: scale(1.1);
      }
      .blue-background {
        background-color: #3550a0;
        .mini-headline, p {
          color:#fff;
        }
      }
    }
  }
}

.section-7 {
  padding-top: 163px;
  background-color: #f8f9fa;
  padding-bottom:150px; 
  .text-center {
    text-align: center;  
    margin:auto;
  }
  .section-7-inner {
    h2 {
      color:#3550a0;
      font-size:33px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 45px;
    }
    .blue-breaker {
      margin:0px auto 50px auto;
    }
    .carousel {
      .element {
        width:33%;
        padding-left:50px;
        padding-right: 50px;
        max-width: 363px;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 0 0 80px rgba(95,96,96,0.7);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 0.4s;
        .img-height {
          height: 135px;
        }
        img {
          height:75px;
          width:auto;
          padding-top:40px;
        }
        .mini-name {
          color:#3550a0;
          font-size: 16px;
          font-weight: 600;
        }
        p {
          color:#6d7276;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
        }
        .blue-border {
          position: absolute;
          width:calc(100% + 9px);
          height:calc(100% + 9px);
          border-radius: 15px;
          border:3px solid #3550a0;
          top:-6px;
          left:-6px;
        }
      }
    }
  }
}


// New CSS
#toolbar{
  li{ 
    a {
      width: 16px;
      height: 16px;
      background-color: #3550a0;
      display: inline-block;
      padding: 0px; 
    } 
  }
}




@media (  max-width:1400px ) {
  .section-4 {
      .section-4-inner {
          .right-side {
              a{padding: 30px 28px;font-size: 20px;}    
          }
      }
  }
} 

@media ( max-width:991px ) {
  .section-2 {
    .section-2-inner {
      .right-side {
        .bigger-img{width: 100%;}
        .smaller-img{width: 253px;height: 245px; 
          img{max-width: 100%;}   
        }
      }
    }
  }
  .section-3 {
    .section-3-inner{gap: 20px;} 
  }
  .section-4 { 
    .section-4-inner {gap: 15px;padding: 30px;
      .right-side {width: auto; 
        a {padding: 21px 20px;font-size: 20px;}  
      }
    }
  }
  .section-5 {
    .section-5-inner {
      .three-boxes{gap: 15px;
        .box-content{padding-left: 15px;padding-right: 15px;}  
      } 
    }
  }
  .zahlen_box {
    min-height: 210px;   
    .stat-number {font-size: 45px;} 
    .stat-title{font-size: 20px;line-height: normal;}
  }
  .title_h3 {
    font-size: 25px;} 
    .footer-wrapper {
      .footer-item {
        margin-bottom: 20px;
      }  
    }
}

@media ( max-width:767px ) {
  .section-2 {padding-top: 90px;padding-bottom:90px;  
    .section-2-inner {display: inline-block;
      .left-side, .right-side{
        width: 100%;max-width: 100%;              
      }
      .right-side{  
        .bigger-img {
          > img{width: 100%;}            
        }
      }           
    } 
  } 
  .section-3 {padding-bottom: 100px; 
    .section-3-inner {display: inline-block;
      .left-side, .right-side{width: 100%;max-width: 100%;}    
      .right-side{margin-top: 20px;} 
    }
  }

  .section-4 { 
    background-color: #3550a0;
    .section-4-inner{display:inline-block;text-align: center;margin: 0;
      .left-side, .right-side{width: 100%;max-width: 100%;}   
      .right-side {
        a{display: inline-block;margin-top: 29px;} 
      }     
    }
  } 
  .section-5{padding-top: 100px;
    .section-5-inner {
      .three-boxes{margin-top: 65px;display: inline-block;
        .box-content{width: 100%;max-width: 100%;margin-bottom: 20px;}   
      } 
    }
  }
  .section-6{margin-bottom: 100px; 
    .section-6-inner{
      .two-boxes{margin-top: 0px;display:inline-block;
        .box-content{padding-left: 15px;padding-right: 15px;width: 100%;max-width: 100%;margin-bottom: 20px;}  
      }
    }
  }
  .section-7 {
    padding-top: 100px;
    padding-bottom: 60px;   
  }
 
}

@media (max-width:507px ) {
  .section-7 {
    .section-7-inner {
      h2{font-size: 25px;}
    }
  }
  .section-5 {
    .section-5-inner {
      h2{font-size: 25px;} 
    }
  }
  .home_banner {
    .banner_box {
      .blue-box-outerline{right: -5px;bottom: -5px;}   
    }
  }
}